import React from "react";
import "./secondsec.css";
import Mi from "..//image/Mi.jfif";

export default function Secondsec() {
  return (
    <div id ="aboutme" className="Container2">
      <div className="Container2-1">
        <div className="Img-Div">
          <img className="Mi" src={Mi} alt="" />
        </div>
        <div className="Text-Div">
          <div className="Text-Div-2">
            <h3 className="laranja">Sobre mim</h3>
            <p>
              Atuo como psicóloga (CRP 05/53128) e como psicoterapeuta corporal
              desde 2016, tendo experiência clínica com adolescentes e adultos.
              Meu trabalho e minha escuta são orientados pela perspectiva
              corporal desde o início da minha trajetória na psicologia clínica.
            </p>
            <p>
              Sou formada em Psicologia pela Universidade Federal Fluminense
              (UFF) e em Terapia Corporal Reichiana pelo Centro de Análise do
              Movimento Vivo (AMV). Minha base é a terapia corporal de
              orientação reichiana, que tem como fundador Wilhelm Reich, mas
              conto também com o estudo de comentadores mais atuais de sua obra,
              além de buscar conhecimento de áreas análogas, como a Experiência
              Somática (SE), a Psicologia Social e estudos sobre gênero e
              sexualidade.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
