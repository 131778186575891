import React, { useState, useRef} from "react";
import "./firstsection.css";
import ML from "../image/ML.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, Link } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Container } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chilling from "..//image/Chilling.png";



export default function FirstSection() {

    const [open, setOpen] = useState(false);

    const handleClickref = () => {
      window.location.href = "https://wa.me/5521991327894?text=Olá, estou buscando terapia e gostaria de mais informações.";
    };

   const handleClick = (param) => {
    const componenteDeDestino = document.getElementById(param);
    const componenteDeDestinoPosicao = componenteDeDestino.getBoundingClientRect().top;
    window.scrollTo({ top: componenteDeDestinoPosicao, behavior: 'smooth' });
    setOpen(false)
  };

  

  const navigationLinks = [
    { name: "Sobre mim", ref: "aboutme"},
    { name: "Localização", ref: "local"},
    { name: "O Consultório", ref: "photos" },
  ];
    

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" color="default">
          <Container maxWidth="md">
            <Toolbar disableGutters>
              <Hidden smDown>
                {navigationLinks.map((item) => (
                  <Link
                    key={item.name}
                    color="gray"
                    variant="button"
                    underline="hover"
                    margin="20px"
                    onClick={() => handleClick(item.ref)}
                  >
                    {item.name}
                  </Link>
                ))}
              </Hidden>
              <Hidden smUp>
                <IconButton>
                  <MenuIcon onClick={() => setOpen(true)} />
                </IconButton>
              </Hidden>
            </Toolbar>
          </Container>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            <IconButton>
              <ChevronRightIcon onClick={() => setOpen(false)} />
            </IconButton>
            <Divider />
            <List className="List-">
              {navigationLinks.map((item) => (
                <ListItem className="List-item" key={item.name}>
                  <Link
                    color="blue"
                    variant="button"
                    underline="none"
                    onClick={() => handleClick(item.ref)}
                  >
                    {item.name}
                  </Link>
                </ListItem>
              ))}
              
            </List>
          </SwipeableDrawer>
        </AppBar>
      </Box>
      <div className="Container">
        <div className="ContainerFilho">
          <div className="Container-1">
            <div className="Profile">
              <div className="Logo-Div">
                <img className="Logo" alt="Logo" src={ML}></img>
              </div>
              <div className="Name-Div">
                <h1>Michelle Lage</h1>
              </div>
              <div className="Function-Div">
                <h1>Psicóloga | Terapeuta Reichiana</h1>
              </div>
            </div>
          </div>
          <div className="Container-2">
            <h1>Atendimentos Presenciais e Online.</h1>
            <h5>
              CRP 05/53128. Atendo adultos e adolescentes. Atendimentos em
              icaraí (Niterói), próximo ao colégio Abel.
            </h5>
            <button style={{cursor:"pointer"}} onClick={handleClickref} className="Button-Agendamento">
              <span>Quero agendar um horário</span>
              <span>
                <WhatsAppIcon />
              </span>
            </button>
          </div>
        </div>

        <Hidden mdDown>
          <div className="Chilling">
            <img src={Chilling} alt="" />
          </div>
        </Hidden>
      </div>
    </>
  );
}
