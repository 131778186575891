import React from 'react'
import "./fourthsec.css"
import Carousel from '@itseasy21/react-elastic-carousel';
import { Card, CardContent } from '@mui/material/';
import { Box } from '@mui/system';
import consult1 from "../image/consult-1.jfif"
import consult2 from "../image/consult-2.jfif"
import consult3 from "../image/consult-3.jfif"
import consult4 from "../image/consult-4.jfif"
import consult5 from "../image/consult-5.jfif"




export default function Fourthsec() {
  return (
    <div id="photos" className='Container4'>
      <Carousel>
        
        <Box className="margin50">
          <Card>
            <CardContent>
              <img src={consult1} alt="foto consultorio" />
            </CardContent>
            
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent>
              <img src={consult2} alt="foto consultorio" />
            </CardContent>
            
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent>
              <img src={consult3} alt="foto consultorio" />
            </CardContent>
            
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent>
              <img src={consult4} alt="foto consultorio" />
            </CardContent>
            
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent>
              <img src={consult5} alt="foto consultorio" />
            </CardContent>
            
          </Card>
        </Box>
        
        
      </Carousel>
    </div>
  )
}

