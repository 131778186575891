
import './App.css';
import FirstSection from './components/firstsection.jsx';
import Fourthsec from './components/fourthsec';
import Secondsec from './components/secondsec';
import Thirdsec from './components/thirdsec';




function App() {
  return (
    <div className="App">
      <FirstSection />
          <Secondsec />
          <Thirdsec />
          <Fourthsec/>
    </div>
  );
}

export default App;
