import React, { useState } from "react";
import "./thirdsec.css";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

export default function Thirdsec() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
  });

  const center = {
    lat: -22.89905,
    lng: -43.11053,
  }
  const containerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  };
  const [map, setMap] = useState(null)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <div id="local" className="thirdsec">
      <div className="Container3">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {<MarkerF position={{lat: -22.89905, lng: -43.11053} }/>}
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
      

      <div className="Text-Div-3">
        <div className="Text-Div-3-1">
          <h3 className="laranja">Localização</h3>
          <p>
            Rua Doutor Paulo Cesar, 63. Niterói - RJ.<br></br>
            <span style={{ color: "orange" }}>Edifício Unic Icaraí.</span>
            <br></br>
            <br />
            <li>Fácil acesso por meios de transporte públicos.</li>
            <li>Possui estacionamento próximo. </li>
            <li>Próximo ao centro da cidade e às principais vias de Icaraí.</li>
          </p>
        </div>
      </div>
    </div>
  );
}
